import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'
import { StaticImage } from 'gatsby-plugin-image'

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <StaticImage
            alt="little hair lounge logo"
            placeholder="blurred"
            height={80}
            src={'../../../images/lhl-logo-cropped.png'}
          ></StaticImage>
        </Link>

        <button
          className="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon className="footer-icon" icon={faBars} />
        </button>
        <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link px-3">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link px-3">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-link px-3">
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/faqs" className="nav-link px-3">
                FAQs
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link px-3">
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link px-3"
                target="_blank"
                rel="noreferrer"
                href={BOOKING_LINK}
              >
                Book Now
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
